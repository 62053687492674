import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useReactTable, flexRender, getCoreRowModel, getPaginationRowModel, getSortedRowModel } from '@tanstack/react-table';
import { Table as BTable } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';
import ReactLoading from 'react-loading';

import ApiService from '../../helpers/apiservice';
import Breadcrumbs from '../../widgets/breadcrumb';
import * as Constants from '../../helpers/constants'

import './../../static/css/userlist.css'

const BreadcrumbOptions = [
  { label: 'Clearout Users', href: '/dashboard/clearout_users', active: false },
  { label: 'Clearout Individual User Activity', href: '/dashboard/clearout_individual_user_activity', active: true },
];

const initialState = {
  userdata: [],
  userName: '',
  isLoading: false,
}

const UserActivities = () => {
  const [state, setState] = useState(initialState)

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const user_id = queryParams.get('user_id');
  const skip = 0;
  const limit = 100;
  const searchText = '';
  const sorting = '';

  useEffect(() => {
    setState(prevState => ({ ...prevState, isLoading: true }));
    const body = {
      co_user_id: user_id,
      skip: skip,
      limit: limit,
      search: searchText,
      sort: sorting,
      filters: {}
    };

    ApiService.getClearoutUsersActivities(body)
      .then((res) => {
        if (res.status === Constants.CO_API.SUCCESS && res.data) {
          const modifiedData = res.data.data.map((item) => ({
            ...item,
            data: JSON.stringify(item.data),
          }));
          setState(prevState => ({ ...prevState, userdata: modifiedData }))
          setState(prevState => ({ ...prevState, userName: res.data.name }))
          console.log(modifiedData);
        } else {
          console.log('Failed to get clearout users activities');
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setState(prevState => ({ ...prevState, isLoading: false }));
      });
  }, [user_id, skip, limit, searchText, sorting]);

  const createdOnTimeFormat = (cell) => {
    let date = moment(cell).format('DD-MMM-YYYY @ h:mm:ss a');
    return (
      <span title={date}>{date}</span>
    );
  };

  const columns = [
    {
      header: 'Sr No.',
      cell: (cell) =>
        <div
          className="text-center px-3"
        >
          {cell.row.index + 1}
        </div>
    },
    {
      header: 'Action',
      accessorKey: 'action',
      cell: (cell) => {
        if (cell.row.original.action) {
          const actionTitleCase = _.startCase(_.toLower(_.replace(cell.row.original.action, /_/g, ' ')));
          return (
            <div className='w-100'>{actionTitleCase}</div>
          );
        } else {
          return null;
        }
      },
    },
    {
      header: 'Action Data',
      cell: (cell) => {
        if (cell.row.original.data) {
          return (
            <pre className='action-data'>{JSON.stringify(JSON.parse(cell.row.original.data), null, 2)}</pre>
          );
        } else {
          return null;
        }
      }
    },
    {
      header: 'Created By',
      accessorKey: 'created_by'
    },
    {
      header: 'Created on',
      accessorKey: 'created_on',
      cell: (cell) => createdOnTimeFormat(cell.row.original.created_on),
    }
  ];

  const table = useReactTable({ columns, data: state.userdata, getCoreRowModel: getCoreRowModel(), getPaginationRowModel: getPaginationRowModel(), getSortedRowModel: getSortedRowModel() });

  return (
    <div className="overflow-y-auto">
      <Breadcrumbs options={BreadcrumbOptions} />
      <h1 className='fs-4 py-3'>{state.userName}&apos;s - User Activities</h1>
      {state.isLoading && (
        <div className="loader-container">
          <ReactLoading
            type="bars"
            color="#007bff"
            height={50}
            width={50}
          />
        </div>
      )}
      {state?.userdata.length > 0 ? (
        <div className='mw-100'>
          <BTable
            striped
            bordered
            hover
            responsive
            size="lg"
          >
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Action</th>
                <th>Action Data</th>
                <th>Created By</th>
                <th>Created On</th>
              </tr>
            </thead>
            <tbody>
              {table.getRowModel().rows.map(row => (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    <td
                      key={cell.id}
                      className='py-3'
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </BTable>
        </div>
      ) : <p className='text-center'>No Activities Found</p>}
      {state?.userdata.length > 0 ? (
        <div className="d-flex justify-content-between mb-5 pt-3">
          <div>
            <select
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
              className="form-select border-none"
            >
              {[10, 20, 30, 50].map((pageSize) =>
                <option
                  key={pageSize}
                  value={pageSize}
                >
                  {pageSize}
                </option>
              )}
            </select>
          </div>
          <div>
            <span className="d-flex align-items-center gap-1">
              {Array.from({ length: table.getPageCount() }, (_, i) =>
                <button
                  key={i}
                  onClick={() => {
                    table.setPageIndex(i);
                  }}
                  className={`border p-1 rounded bg-transparent ${i === table.getState().pagination.pageIndex ? 'active' : ''
                  }`}
                >
                  {i + 1}
                </button>
              )}
              <button
                onClick={() => {
                  table.previousPage();
                }}
                disabled={!table.getCanPreviousPage()}
              >
                <i className="fa-solid fa-arrow-left"></i>
              </button>
              <button
                onClick={() => {
                  table.nextPage();
                }}
                disabled={!table.getCanNextPage()}
                className="p-1 border disabled:opacity-30"
              >
                <i className="fa-solid fa-arrow-right"></i>
              </button>
            </span>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default UserActivities;
